.sideSliderSidelabel {
    left: -60px;
    width: 60px;
    height: 60px;
    position: absolute;
    border-top-left-radius: 25%;
    border-bottom-left-radius: 25%;
    background-image: url("https://i.imgur.com/qawNHPZ.png");        
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}
.sideSliderCloseButton {
    width: 40px;
    height: 40px;
    background-image: url("https://i.imgur.com/AMtO0A8.png");        
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}